import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/bonus-game/left-arrow.svg';
import { useNavigate } from 'react-router-dom';
import gameRakingsPath from 'src/assets/img/bonus-game/game-rankings.webp';
import { LeaderBoard } from './components/LeaderBoard';
import { useEffect, useState } from 'react';
import { bonusGameApi } from 'src/api/bonus-game';

const PageWrapper = styled(VerticalView)`
  > * {
    padding: 0 20px;
  }
`;

const HeaderTitle = styled.p`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

const HeaderWrapper = styled(HorizontalView)`
  ${HeaderTitle} {
    margin: auto;
    position: relative;
    left: -12px;
  }
  margin-bottom: 32px;
`;

const Image = styled.img`
  width: 100%;
  padding: 0;
`;

const ContentTitle = styled.p`
  font-family: Public-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 170%;
  margin-top: 20px;
`;
const ContentDescription = styled.p`
  font-family: Public-Sans, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 170%;
`;
const SeasonDuration = styled.p`
  font-family: Public-Sans, sans-serif;
  font-size: 25.232px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
`;

function formatTime(seconds) {
  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;

  const days = Math.floor(seconds / secondsInDay);
  seconds %= secondsInDay;

  const hours = Math.floor(seconds / secondsInHour);
  seconds %= secondsInHour;

  const minutes = Math.floor(seconds / secondsInMinute);

  return `${days}d : ${hours.toString().padStart(2, '0')}h : ${minutes
    .toString()
    .padStart(2, '0')}m`;
}

export const LeaderBoardPage = ({ isSeasonOver }) => {
  const navigate = useNavigate();
  const [leftTime, setLeftTime] = useState('0d : 00h : 00m');
  useEffect(() => {
    (async () => {
      if (isSeasonOver) {
        setLeftTime('0d : 00h : 00m');
        return;
      }
      const resetDateRes = await bonusGameApi.resetDate();
      const interval = setInterval(() => {
        const currentTime = Date.now();
        if (currentTime >= resetDateRes) {
          return clearInterval(interval);
        }

        const formattedTime = formatTime(
          Math.floor((resetDateRes - currentTime) / 1000),
        );
        setLeftTime(formattedTime);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    })();
  }, [isSeasonOver]);

  return (
    <PageWrapper>
      <HeaderWrapper>
        <button
          onClick={() =>
            isSeasonOver
              ? window.ReactNativeWebView.postMessage(
                  JSON.stringify({ type: 'navigate-back' }),
                )
              : navigate('/bonus-game')
          }>
          <LeftArrowIcon color="rgba(82, 90, 103, 1)" width={24} height={24} />
        </button>

        <HeaderTitle>Top 500</HeaderTitle>
      </HeaderWrapper>
      <Image src={gameRakingsPath} />
      <VerticalView style={{ alignItems: 'center' }}>
        <ContentTitle>Leaderboard</ContentTitle>
        <ContentDescription>
          The leaderboard will be reset in:
        </ContentDescription>
        <SeasonDuration>{leftTime}</SeasonDuration>
      </VerticalView>
      <LeaderBoard />
    </PageWrapper>
  );
};
