import styled from 'styled-components';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';
import { useEffect, useState } from 'react';
import { increaseNumberAnimation } from './helper.js';

const GameBalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 12px 0 20px;
`;
const GameBalanceAmount = styled.p`
  color: #fffefd;
  font-family: Public-Sans, sans-serif;
  font-size: 24px;
  font-weight: 500;
`;

const bounusTotalAmountId = 'bonus_total_amount';

export const GameBalance = ({ balance, endNumberBalance }) => {
  const [currentBalance, setCurrentBalance] = useState(balance);
  const [endNumber, setEndNumber] = useState(endNumberBalance);

  useEffect(() => {
    setEndNumber(endNumberBalance);
  }, [endNumberBalance]);

  const handleAddBalance = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'show-extra-coins-popup' }),
    );
  };

  useEffect(() => {
    increaseNumberAnimation(
      bounusTotalAmountId,
      endNumber,
      currentBalance,
      10,
      1,
      setCurrentBalance,
    );
  }, [currentBalance, endNumber, setCurrentBalance]);

  return (
    <GameBalanceWrapper>
      <CoinIcon width={24} height={24} />
      <GameBalanceAmount>
        <span id={bounusTotalAmountId}>{currentBalance}</span>
        <button
          onClick={handleAddBalance}
          style={{
            color: '#AADE16',
            fontWeight: 700,
            paddingLeft: 4,
            paddingRight: 4,
          }}>
          +
        </button>
      </GameBalanceAmount>
    </GameBalanceWrapper>
  );
};
