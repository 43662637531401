import styled from 'styled-components';
import { VerticalView } from 'src/components/FlexViews';
import { Button } from 'src/pages/BonusGame/components/Button';
import treePolygonPath from 'src/assets/img/bonus-game/tree-polygon.webp';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-menu.svg';
import { useNavigate } from 'react-router-dom';

const PageWrapper = styled.div`
  padding: 20px;
  background-color: #090d12;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::-webkit-scrollbar {
    display: none;
  }
  justify-items: center;
  height: 100vh;
`;

const ContentWrapper = styled(VerticalView)`
  gap: 16px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
const Title = styled.p`
  color: #fffefd;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.56px;
`;

const Subtitle = styled.p`
  color: #fcfcfc;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 20px;
  font-weight: 500;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const ButtonStyled = styled.button`
  position: absolute;
  right: 16px;
  top: 20px;
`;

export const WelcomePage = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/bonus-game/season-info');
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <ButtonStyled
          onClick={() =>
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: 'navigate-back' }),
            )
          }>
          <CloseIcon color="rgba(41, 49, 62, 1)" />
        </ButtonStyled>
        <Title>Welcome to My Tree! </Title>
        <Subtitle>Earn USDT & Bonuses by collecting daily rewards</Subtitle>
        <StyledImage src={treePolygonPath} />
      </ContentWrapper>
      <Button title="Start" color={'positive'} onClick={handleStart} />
    </PageWrapper>
  );
};
