import { useState } from 'react';

export function useLoading(callback) {
  const [loading, setLoading] = useState(true);

  const execute = async (...args) => {
    setLoading(true);
    try {
      await callback(...args);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return [execute, loading];
}
