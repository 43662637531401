import { Route, Routes, useNavigate } from 'react-router-dom';
import { GamePage } from './screens/GamePage';
import { WelcomePage } from './screens/WelcomePage';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { SeasonOne } from './screens/SeasonOne';
import { SeasonInfo } from './screens/SeasonInfo';
import { DailyRewardsPage } from './screens/DailyRewardsPage';
import { LeaderBoardPage } from './screens/LeaderBoardPage';
import styled from 'styled-components';
import { bonusGameApi } from 'src/api/bonus-game';
import { NotDepositedModal } from './screens/GamePage/components/NotDepositedModal';
import { useLoading } from 'src/hooks/useLoading';
import ScrollToTop from 'src/components/ScrollToTop';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
  }
  height: 100%;
  margin: 20px 0;
`;

const rootPath = '/bonus-game';

export const BonusGameRoutes = () => {
  const [isGameAvailable, setGameAvailable] = useState(false);
  const [randomBonusModalVisible, setRandomBonusModalVisible] = useState();
  const [isSeasonOver, setSeasonOver] = useState(true);

  const [resetDate, isResetDateLoading] = useLoading(async () => {
    const resetDateRes = await bonusGameApi.resetDate();
    const currentTime = Date.now();

    if (currentTime > resetDateRes) {
      setSeasonOver(true);
    } else {
      setSeasonOver(false);
    }
  });

  const [checkAvailability, isGameAvailabilityLoading] = useLoading(
    async () => {
      const isAvailableResponse = await bonusGameApi.checkAvailability();
      setGameAvailable(isAvailableResponse.result);
    },
  );

  const [getStats, isStatLoading] = useLoading(async () => {
    if (isGameAvailable) {
      const getStatRes = await bonusGameApi.getStat();
      setRandomBonusModalVisible(getStatRes.result.randomRewardAvailable);
    }
  });

  useEffect(() => {
    getStats();
  }, [isGameAvailable]);

  useEffect(() => {
    resetDate();
    checkAvailability();
  }, []);

  const isLoading =
    isResetDateLoading || isGameAvailabilityLoading || isStatLoading;

  if (isLoading) return null;

  if (!isGameAvailable) {
    return <NotDepositedModal />;
  }

  return (
    <Layout id="bonus-game-layout">
      <ScrollToTop />

      {isSeasonOver ? (
        <Routes>
          <Route
            path={`${rootPath}`}
            element={<LeaderBoardPage isSeasonOver={isSeasonOver} />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            exact
            path={rootPath}
            element={
              <GamePage
                randomBonusModalVisible={randomBonusModalVisible}
                setRandomBonusModalVisible={setRandomBonusModalVisible}
              />
            }
          />
          <Route exact path={`${rootPath}/welcome`} element={<WelcomePage />} />
          <Route
            exact
            path={`${rootPath}/season-info`}
            element={<SeasonInfo />}
          />
          <Route
            exact
            path={`${rootPath}/season-one`}
            element={<SeasonOne />}
          />
          <Route
            path={`${rootPath}/daily-rewards`}
            element={<DailyRewardsPage />}
          />
          <Route
            path={`${rootPath}/top-leaderboard`}
            element={<LeaderBoardPage isSeasonOver={isSeasonOver} />}
          />
        </Routes>
      )}
    </Layout>
  );
};
