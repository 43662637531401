import { useEffect, useRef, useState } from 'react';
import {
  EditableInput,
  ErrorText,
  GameHeaderWrapper,
  Label,
  LabelWrapper,
  QuestionMarkWrapper,
} from './styled';
import { ReactComponent as EditTitleIcon } from 'src/assets/icons/bonus-game/edit-title.svg';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/bonus-game/left-arrow.svg';
import { ReactComponent as QuestionMarkIcon } from 'src/assets/icons/bonus-game/questionmark.svg';
import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { useNavigate } from 'react-router-dom';
import { bonusGameApi } from 'src/api/bonus-game';

const EditableLabel = ({ gameStat, setGameStat, setErrorText }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    setText(gameStat?.bonusTreeName ?? '');
  }, [gameStat?.bonusTreeName]);

  const handleToggleEdit = () => {
    setErrorText('');
    setIsEditing(!isEditing);
  };

  const handleChange = event => {
    setText(event.target.value);
  };

  const handleBlur = async event => {
    setIsEditing(false);

    if (text === gameStat?.bonusTreeName) {
      return;
    }

    if (text.includes('BonusTree')) {
      setErrorText(
        "Your name cannot include 'BonusTree'. Please choose a different name",
      );
      setText(gameStat?.bonusTreeName);
      return;
    }

    try {
      const changeNameRes = await bonusGameApi.changeName({ name: text });
      if (changeNameRes?.result) {
        setGameStat({ ...gameStat, bonusTreeName: text });
      } else {
        setText(gameStat?.bonusTreeName);
      }
    } catch (e) {
      setText(gameStat?.bonusTreeName);
      setErrorText(e.response.data.message);
      console.error(e);
    }
  };

  return (
    <LabelWrapper onClick={handleToggleEdit}>
      {isEditing ? (
        <EditableInput
          type="text"
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <HorizontalView style={{ gap: '8px' }}>
          <Label>{text}</Label>
          <EditTitleIcon />
        </HorizontalView>
      )}
    </LabelWrapper>
  );
};

export const GameHeader = ({ gameStat, setGameStat }) => {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState('');
  function handleGoBack() {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'navigate-back' }),
    );
  }

  return (
    <VerticalView style={{ height: 50 }}>
      <GameHeaderWrapper>
        <button onClick={handleGoBack}>
          <LeftArrowIcon color="rgba(82, 90, 103, 1)" width={24} height={24} />
        </button>
        <EditableLabel
          setErrorText={setErrorText}
          gameStat={gameStat}
          setGameStat={setGameStat}
        />
        <QuestionMarkWrapper onClick={() => navigate('/bonus-game/season-one')}>
          <QuestionMarkIcon color="rgba(82, 90, 103, 1)" />
        </QuestionMarkWrapper>
      </GameHeaderWrapper>
      <ErrorText>{errorText}</ErrorText>
    </VerticalView>
  );
};
