import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { Button } from 'src/pages/BonusGame/components/Button';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-menu.svg';
import { ReactComponent as ArrowCircleIcon } from 'src/assets/icons/bonus-game/arrow-circle.svg';
import { ReactComponent as CreditCardPlusIcon } from 'src/assets/icons/bonus-game/credit-card-plus.svg';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 0 20px;
`;

const ModalWrapper = styled(VerticalView)`
  position: relative;
  width: 100%;
  padding: 32px 24px;
  border-radius: 12px;
  border: 1px solid #29313e;
  background: #171d25;
`;
const Title = styled.p`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(123deg, #03b8ff 14.45%, #fa52a0 76.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
`;

const GameRule = styled.p`
  color: #fcfcfc;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const MoneyBag = styled.p`
  font-size: 40px;
  align-self: center;
  margin-bottom: 32px;
`;

const ButtonStyled = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const NotDepositedModal = () => {
  const handleBuyCrypto = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'navigate-buy-screen',
      }),
    );
  };

  const handleReceiveCrypto = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'navigate-receive-screen',
      }),
    );
  };

  return (
    <ModalOverlay>
      <ModalWrapper>
        <ButtonStyled
          onClick={() =>
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: 'navigate-back' }),
            )
          }>
          <CloseIcon color="#434B58" />
        </ButtonStyled>
        <Title>Welcome</Title>
        <GameRule>
          To unlock ‘Tree-game’ your balance needs to be above 1 dollar.
        </GameRule>
        <MoneyBag>💰</MoneyBag>
        <VerticalView style={{ gap: 12 }}>
          <Button
            title={
              <HorizontalView style={{ gap: 8 }}>
                <CreditCardPlusIcon /> <p>Buy crypto</p>
              </HorizontalView>
            }
            color={'positive'}
            onClick={handleBuyCrypto}
          />
          <Button
            title={
              <HorizontalView style={{ gap: 8 }}>
                <ArrowCircleIcon /> <p>Receive crypto</p>
              </HorizontalView>
            }
            color={'positive'}
            onClick={handleReceiveCrypto}
          />
        </VerticalView>
      </ModalWrapper>
    </ModalOverlay>
  );
};
