import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-menu.svg';
import { ReactComponent as TShirtIcon } from 'src/assets/icons/bonus-game/t-shirt.svg';
import { ReactComponent as StarIcon } from 'src/assets/icons/bonus-game/star.svg';
import { ReactComponent as DiscordIcon } from 'src/assets/icons/bonus-game/discord.svg';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BonusRuleRowItem } from './components/BonusRuleRowItem';
import { BonusPlacePrizes } from './components/BonusPlacePrizes';
import { Button } from '../../components/Button';
import { useState } from 'react';
import { WelcomeBonusModal } from './components/WelcomeBonusModal';

const PageWrapper = styled(VerticalView)`
  padding: 20px 20px 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const ModalWrapper = styled(VerticalView)`
  position: relative;
  border-radius: 12px;
  border: 1px solid #29313e;
  background: #171d25;
  padding: 32px 24px;
`;
const ContentWrapper = styled(VerticalView)`
  margin-top: 26px;
`;

const HeaderTitle = styled.p`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  flex: 1;
  margin-left: -12px;
`;

const ContentTitle = styled.p`
  color: #aade16;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 12px;
`;

const ContentDescription = styled.p`
  color: #c6cfd8;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
`;

const CloseIconWrapper = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const SeasonInfo = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleGoBack = () => {
    navigate('/bonus-game/welcome');
  };
  return (
    <PageWrapper>
      <ModalWrapper>
        <CloseIconWrapper onClick={handleGoBack}>
          <CloseIcon color={'rgba(41, 49, 62, 1)'} />
        </CloseIconWrapper>
        <ContentWrapper>
          <ContentTitle>Welcome to Season One!</ContentTitle>

          <BonusPlacePrizes />

          <VerticalView style={{ gap: 16, marginTop: 32 }}>
            <BonusRuleRowItem
              title={'Top 200 members'}
              description={'Ambassador role on Haqabi Discord'}
              icon={<DiscordIcon />}
            />
          </VerticalView>
          <Button
            style={{ marginTop: 32 }}
            title="Start"
            color="positive"
            onClick={() => setShowModal(true)}
          />
        </ContentWrapper>
        {showModal && <WelcomeBonusModal setShowModal={setShowModal} />}
      </ModalWrapper>
    </PageWrapper>
  );
};
